import React, { useCallback } from 'react';
import { ToastContainer as Toast, toast, ToastPosition, ToastTransition, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type ToastContainerProps = { autoClose?: number; position?: ToastPosition; transition?: ToastTransition; }

const CloseButton = ({ closeToast }:{ closeToast: any }) => (
    <div
        className='toast-message-close-button flex justify-center items-center aspect-[2] bg-black/[0.1] text-theme_1_white font-bold p-[11px]'
        onClick={closeToast}
    >
        &#10005;
    </div>
);

const ToastContainer: React.FC<ToastContainerProps> = ({ autoClose, position, transition }) => {
    const getToastClass = useCallback(({ type, defaultClassName }) => {
        let className = `${defaultClassName} !min-h-[35px] rounded-[6px] !p-0 `;

        switch (type) {
            case toast.TYPE.SUCCESS:
                className += '!bg-theme_1_green';
                break;
            case toast.TYPE.ERROR:
                className += '!bg-theme_1_red';
                break;
            default:
                className += '!bg-theme_1_grey1';
                break;
        }

        return className;
    }, []);

    return (
        <Toast
            position={position}
            autoClose={autoClose}
            transition={transition}
            hideProgressBar
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            icon={false}
            className={'!w-fit !min-w-[419px] !max-w-[510px] xsScreen:!min-w-full'}
            toastClassName={getToastClass}
            bodyClassName='!text-theme_1_white !text-[12px] !font-bold !py-[8.5px] !px-[20px] leading-[15px]'
            closeButton={CloseButton}
        />
    );
};

ToastContainer.defaultProps = {
    autoClose: 5000,
    position: 'top-right',
    transition: Slide,
};

export default ToastContainer;
